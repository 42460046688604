import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
    isEmpty,
    map,
    compact,
    filter,
    indexOf,
    orderBy,
    uniqBy,
} from 'lodash';
import User from './User';
import './UserList.scss';
const UserList = (props) => {
    const {
        chatData,
        setRoomInfo,
        selectedId,
        tab,
        searchText,
        createRoom,
        inviteUsers,
        isDisable,
        isAdmin,
        errorAlerts,
        selectedKabiCenter,
        setKabiCenter,
    } = props;
    // GET MEMBERS LIST IN EXISTING ROOM
    useMemo(async () => {
        if (!isEmpty(chatData) && !isEmpty(chatData.chatInfo)) {
            const {
                chatInfo: { memberName, rooms },
            } = chatData;
            const filterRoom = await Promise.all(
                map(rooms, async (room) => {
                    const {
                        lastMessage,
                        channelState: { uniqueName, friendlyName, attributes },
                    } = room;
                    const [section, , adminId] = uniqueName.split('-');
                    const { clinicId } = attributes;
                    // if (
                    //     section === 'NUTRITIONISTS' &&
                    //     tab === 'NUTRITIONISTS'
                    // ) {
                    //     // const memberList = await room.getMembers();
                    //     // const [, member] = map(memberList, 'identity');
                    //     // console.log(memberList, room);
                    //     room.delete();
                    // }
                    if (section === tab) {
                        const unReadMsg = await room.getUnconsumedMessagesCount();
                        const msgCount = await room.getMessagesCount();
                        const res = {
                            memberName,
                            room,
                            usersId: isAdmin ? friendlyName : attributes?.mId,
                            msgCount,
                            unReadMsg: unReadMsg === null ? 0 : unReadMsg,
                            lastMsgTime: lastMessage?.timestamp ?? '',
                            clinicId,
                        };
                        if (section === 'NUTRITIONISTS') {
                            if (isAdmin && adminId === memberName) return res;
                            if (!isAdmin) return res;
                        } else return res;
                    }
                })
            );
            let memberList = uniqBy(
                orderBy(
                    compact(filterRoom),
                    ['unReadMsg', 'lastMsgTime'],
                    ['desc', 'desc']
                ),
                'usersId'
            );
            if (process.env.REACT_APP_LANGUAGE === 'es-CO') {
                memberList = memberList.filter(i => i.clinicId == selectedKabiCenter);
            }
            setmemberInfo(memberList);
        }
    }, [chatData, tab, isAdmin, selectedKabiCenter]);
    const [memberInfo, setmemberInfo] = useState(undefined);
    const getOtherNutriUsers = useCallback((users, info) => {
        return filter(users, (user) => {
            try {
                const usersId = map(info, 'usersId');
                //user?.externalId
                return indexOf(usersId, user?.id.toString()) === -1
                    ? user
                    : false;
            } catch (e) {
                return false;
            }
        });
    }, []);
    const nutriUsers =
        tab === 'NUTRITIONISTS' && memberInfo !== undefined && isAdmin
            ? getOtherNutriUsers(inviteUsers, memberInfo)
            : [];
    useLayoutEffect(() => {
        return () => setKabiCenter(6);
    }, []);
    return (
        <div className="UserList-container">
            {memberInfo === undefined ? (
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
            ) : (
                <div className="patient-list-info">
                    {memberInfo.map((_, index) => {
                        return !_?.curMember ? (
                            <User
                                key={index}
                                roomInfo={_.room}
                                unRdMsg={_.unReadMsg}
                                msgCount={_.msgCount}
                                curIndex={index}
                                selectedIndex={selectedId?.curIndex}
                                setRoomInfo={setRoomInfo}
                                memberInfo={_.memberName}
                                userId={_.usersId}
                                category={tab}
                                createRoom={createRoom}
                                inviteUsers={inviteUsers}
                                isDisable={isDisable}
                                searchText={searchText}
                                isAdmin={isAdmin}
                                chatClient={chatData?.chatInfo?.chatClient}
                                userDetails={chatData?.chatInfo?.userDetails}
                                errorAlerts={errorAlerts}
                            />
                        ) : null;
                    })}
                    {map(nutriUsers, (nutriUser, index) => {
                        return (
                            <User
                                key={index}
                                curIndex={memberInfo.length + index}
                                selectedIndex={selectedId?.curIndex}
                                setRoomInfo={setRoomInfo}
                                msgCount={0}
                                memberInfo={chatData?.chatInfo?.memberName}
                                category={tab}
                                createRoom={createRoom}
                                inviteUsers={nutriUser}
                                userId={nutriUser.id}
                                isDisable={isDisable}
                                searchText={searchText}
                                isAdmin={isAdmin}
                                chatClient={chatData?.chatInfo?.chatClient}
                                userDetails={chatData?.chatInfo?.userDetails}
                                errorAlerts={errorAlerts}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

UserList.propTypes = {
    createRoom: PropTypes.func.isRequired,
};

export { UserList };
