import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Image, Popup } from '_components';
import { promotionsDiscount, edit as EDITIMAGE, iconDel } from 'images';
import { isEmpty } from 'lodash';
import './Card.scss';
const Card = (props) => {
    const [isDisable, setDisable] = useState(false);
    const {
        card: { title, description, edit, discount, id, ...rest },
        more: { cardEditText, cardDeleteText },
        editPromo,
        delPromo,
    } = props;
    const button = (
        <Icon name="ellipsis vertical" size="large" className="card-dmi-icon" />
    );
    const modalContent = (
        <div className="card-dmi-more-container">
            <ul className={`card-dmi-more-list ${isDisable ? 'disable' : ''}`}>
                <li
                    onClick={() => {
                        editPromo({
                            title,
                            description,
                            edit,
                            discount,
                            id,
                            ...rest,
                        });
                        setDisable(true);
                    }}
                >
                    <div className="content">
                        <Image className="content-img" src={EDITIMAGE} />
                        {cardEditText}
                    </div>
                </li>
                <li
                    onClick={() => {
                        delPromo(id);
                        setDisable(true);
                    }}
                >
                    <div className="content">
                        <Image className="content-img" src={iconDel} />
                        {cardDeleteText}
                    </div>
                </li>
            </ul>
        </div>
    );
    return (
        <div className={`card-dmi ${isDisable ? 'disable' : ''}`}>
            <div className={`card-dmi-img ${discount ? 'discount' : 'custom'}`}>
                {discount && (
                    <Image
                        src={
                            isEmpty(rest?.image)
                                ? promotionsDiscount
                                : rest?.image
                        }
                        className="card-img-disc"
                    />
                )}
            </div>
            <div className="card-dmi--data">
                <div className="card-dmi-heading"> {title}</div>
                <div className="card-dmi-subheading">
                    <label> {description}</label>
                    {edit && (
                        <>
                            <Popup
                                className="card-dmi-popup"
                                button={button}
                                content={modalContent}
                                position="bottom right"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
Card.defaultProps = {
    card: {},
    editPromo: () => {},
};
Card.propTypes = {
    card: PropTypes.object.isRequired,
    editPromo: PropTypes.func.isRequired,
};

export { Card };
