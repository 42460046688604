import { getPromoList } from "_utils";

export const INIT = {
    loginData: { userDetails: { loggedIn: false }, loading: false, lang: process.env.REACT_APP_LANGUAGE },
    forgotData: { loading: false },
    resetData: { loading: false },
    adminList: {
        loading: false,
        faliure: null,
        // FOR ADD EDIT DELETE CHECK
        success: false,
        result: { list: [] },
        params: {},
        centerList: {
            loading: false,
            faliure: null,
            result: [],
        },
    },
    nutriList: {
        loading: false,
        resetLoading: false,
        faliure: null,
        // FOR EDIT CHECK
        success: false,
        result: { list: [] },
        params: {},
        assignePatientList: {
            loading: false,
            faliure: null,
            result: [],
        },
        csvLoading: false,
        csvParams: {},
        uploaded: false,
        uploading: false,
        uploadStatus: false,
    },
    patientList: {
        loading: false,
        faliure: null,
        result: { list: [] },
        params: {},
        ticketDetails: {},
        isModalOpen: false,
        isModalLoad: false,
        modalData:{}
    },
    files: {
        list: [],
    },
    mapData: {
        loading: false,
        faliure: null,
        result: { list: [] },
        params: {},
        uploaded: false,
        uploading: false,
        uploadStatus: false,
    },
    upgradeList: {
        loading: false,
        recordUpating: false,
        faliure: null,
        result: { list: [] },
        params: {},
        selectedId: {},
        csvLoading: false,
        csvParams: {},
        uploaded: false,
        uploading: false,
        uploadStatus: false,
    },
    promoList: getPromoList(process.env.REACT_APP_LANGUAGE),
    chatList: {
        loading: false,
        token: null,
        inviteUsers: [],
        calling: {},
        nutri: { loading: false, result: {}, faliure: null },
        selectedKabiCenter: "6",
        tabs: {
            PATIENTS: {
                faliure: null,
                chatHistory: [],
                chatUsers: [],
                details: {},
                selectedUser: {},
                isAudioMsgRunning: false,
            },
            NUTRITIONISTS: {
                faliure: null,
                chatHistory: [],
                chatUsers: [],
                details: {},
                selectedUser: {},
                isAudioMsgRunning: false,
            },
            KETOCENTER: {
                faliure: null,
                chatHistory: [],
                chatUsers: [],
                details: {},
                selectedUser: {},
                isAudioMsgRunning: false,
            },
        },
    },
    gdprList: {
        currentTerm: '',
        currentPrivacy: '',
        tabs: {
            PRIVACY_POLICY: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
            TERM_CONDITION: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
            MARKET: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
        },
    },
    infoList: {
        loading: false,
        tabs: {
            THE_KIDNEYS: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
            CHRONIC_KIDNEY_DISEASE: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
            TREATMENT_OPTIONS: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
            WHAT_TO_EAT: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
        },
    },
    tips: {
        loading: false,
        tabs: {
            RECIPES: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
            NUTRITIONAL: {
                faliure: null,
                foodList: [],
                foodCategory: [],
                unity: [],
                params: {},
                loading: false,
            },
            PHYSICAL: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
            GENERAL: {
                faliure: null,
                result: { list: [] },
                params: {},
            },
        },
    },
    newsList: {
        loading: false,
        faliure: null,
        result: { list: [] },
        params: {},
    },
    menuList: {
        isAdmin: true,
        items: [
            'Admins',
            'Nutritionists',
            'Patients',
            'Activar',
            'Promotions',
            'Map',
            'Chat',
            'Tips',
            'Info',
            'News',
            'Gdpr',
        ],
        selected: 'Admins',
    },
    alertData: {},
    patientMedicalRecords: {
        loading: false,
        faliure: null,
        patientInfo: {},
        updatedInfo: {},
        BMIRecord: {},
        bodyMeasures: { result: {}, loading: false },
        analytics: { result: {}, loading: false },
        health: { loading: false, result: {}, faliure: null },
        upgrade: { loading: false, result: { list: [] }, params: {} },
        symptoms: [],
        csvLoading: false,
        csvParams: {},
        uploaded: false,
        uploading: false,
        uploadStatus: false,
    },
};